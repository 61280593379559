import React from 'react';
import BootstrapSmartTable from '@admin/components/BootstrapSmartTable';
import {Column} from '@components/BootstrapSmartTable';
import ReferralAdmin from '@interfaces/ReferralAdmin';
import Card from 'react-bootstrap/Card';
import {ReferralStatusEnum} from '@interfaces/GeneratedEnums';
import numeral from 'numeral';
import {Link} from 'react-router-dom';
import updateReferral from '@hornet-api/ambassador/updateReferralAdmin';
import {YesNoEnum} from '@interfaces/Enums';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import FontAwesomeIcon from "@admin/components/icons/FontAwesomeIcon";
import deleteReferral from "@hornet-api/ambassador/deleteReferral";
import {alertApiErrors} from "@common/errors";
import sendReferralEmail from "@hornet-api/ambassador/sendReferralEmail";
import {loadingRelease, loadingTrigger} from "@admin-ui/components/AdminLoadingOverlay";
import {refreshAmbassador} from "@components/NotificationSidebar/utils";

type Props = {
  referrals: ReferralAdmin[];
  loading: boolean;
  tableName: string;
  updateReferrals(): Promise<void>;
  allowPayment?: boolean;
};

const ReferralsTable = ({ referrals, loading, updateReferrals, tableName }: Props) => {
  const columns: Column<ReferralAdmin>[] = [
    {
      id: 'email',
      title: 'Email',
      field: 'email',
      filter: true,
      render: (row) => <a href={'mailto:' + row.email}>{row.email}</a>,
      onEdit: async (row) => {
        await updateReferral(row.id, {
          email: row.email,
        });
        updateReferrals();
      },
    },
    {
      id: 'name',
      title: 'Name',
      field: 'name',
      filter: true,
    },
    {
      id: 'phone',
      title: 'Phone',
      field: 'phoneNumber.phoneNumber',
      filter: true,
      ignoreColumnCheck: true,
      editLock: (row) => {
        return !row.canEditPhoneNumber
      },
      render: (row) => `${row.phoneNumber?.phoneNumber ?? ''}`,
      onEdit: async (row) => {
        console.log('row.phoneNumber', row.phoneNumber)
        try {
          await updateReferral(row.id, {
            phoneNumber: row.phoneNumber?.phoneNumber  && row.phoneNumber?.phoneNumber !== '' ? {
              id: row.phoneNumber?.id,
              phoneNumber: row.phoneNumber?.phoneNumber ?? '',
              isPrimary: row.phoneNumber?.isPrimary ?? true,
            } : null
          });
          updateReferrals();
        } catch(e) {
          alertApiErrors(e)
        }

      },
    },
    {
      id: 'isWillingToInvest',
      title: 'Willing to Invest',
      field: 'isWillingToInvest',
      filter: true,
      lookup: YesNoEnum,
      onEdit: async (row) => {
        await updateReferral(row.id, {
          isWillingToInvest: [true, 'true'].includes(row.isWillingToInvest),
        });
        updateReferrals();
      },
    },
    {
      id: 'hasPreviousRelationship',
      title: 'Previous Relationship',
      field: 'hasPreviousRelationship',
      filter: true,
      lookup: YesNoEnum,
      onEdit: async (row) => {
        await updateReferral(row.id, {
          hasPreviousRelationship: [true, 'true'].includes(row.hasPreviousRelationship),
        });
        updateReferrals();
      },
    },
    {
      id: 'hasMinimumInvestment',
      title: 'Minimum Investment',
      field: 'hasMinimumInvestment',
      filter: true,
      lookup: YesNoEnum,
      onEdit: async (row) => {
        await updateReferral(row.id, {
          hasMinimumInvestment: [true, 'true'].includes(row.hasMinimumInvestment),
        });
        updateReferrals();
      },
    },
    {
      id: 'ambassador',
      title: 'Ambassador',
      field: 'ambassador.name',
      filter: true,
      render: (row) =>
        row.ambassador?.id ? (
          <Link to={`/contact/show/${row.ambassador.id}/ambassador`}>{row.ambassador.name}</Link>
        ) : (
          ''
        ),
    },
    {
      id: 'contact',
      title: 'Contact',
      field: 'contact',
      filter: true,
      render: (row) =>
        row.contact?.id ? (
          <Link to={`/contact/show/${row.contact.id}`}>{row.contact.name}</Link>
        ) : (
          ''
        ),
    },
    {
      id: 'status',
      title: 'Status',
      field: 'status',
      filter: true,
      lookup: ReferralStatusEnum,
      onEdit: async (row) => {
        if (row.status === 'REFERRAL_FEE_PAID') {
          // only allow to pay if the referral is ready to pay
          if (row.isReadyToBePaid) {
            await updateReferral(row.id, { status: row.status });
            updateReferrals();
          } else {
            alert('Referral is not ready to be paid');
          }
        } else {
          await updateReferral(row.id, {status: row.status}).then(refreshAmbassador);
          updateReferrals();
        }
      },
    },
    {
      id: 'referralFeeAmount',
      title: 'Referral Fee Amount',
      field: 'referralFeeAmount',
      filter: true,
      render: (row) => (
        <>{row.referralFeeAmount ? numeral(row.referralFeeAmount).format('$0,0.00') : ''}</>
      ),
      onEdit: async (row) => {
        await updateReferral(row.id, { referralFeeAmount: row.referralFeeAmount });
        updateReferrals();
      },
    },
    {
      id: 'isReadyToBePaid',
      title: 'Pay',
      field: 'isReadyToBePaid',
      filter: true,
      lookup: YesNoEnum,
    },
    {
      id: 'act',
      title: 'Actions',
      field: 'actions',
      align: 'center',
      oneLine: false,
      actionBar: true,
      render: (rowData) => {
        return (
          <>
            <OverlayTrigger
              placement="bottom-end"
              overlay={<Tooltip id={`tt-send-ref-${rowData.id}`}>Send Invite Email</Tooltip>}
            >
              <button
                className="btn btn-sm btn-success mr-2"
                style={{marginRight: 5}}
                onClick={async () => {
                  if (window.confirm('Are you sure you want to resend invite email?')) {
                    const t = loadingTrigger();
                    try {
                      await sendReferralEmail(rowData.id);
                      updateReferrals();
                      loadingRelease(t);
                    } catch (e) {
                      alertApiErrors(e)
                      loadingRelease(t);
                    }
                  }
                }}
              >
                <FontAwesomeIcon icon="envelope" />
              </button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom-end"
              overlay={<Tooltip id={`tt-del-ref-${rowData.id}`}>Delete</Tooltip>}
            >
              <button
                className="btn btn-sm btn-danger"
                style={{marginRight: 5}}
                onClick={async () => {
                  if (window.confirm('Are you sure you want to delete this referral?')) {
                    const t = loadingTrigger();
                    try {
                      await deleteReferral(rowData.id);
                      updateReferrals();
                      loadingRelease(t);
                    } catch(e) {
                      alertApiErrors(e)
                      loadingRelease(t);
                    }
                  }
                }}
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </OverlayTrigger>

          </>
        );
      }
    },
  ];

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>{tableName}</Card.Title>
        </Card.Header>
        <Card.Body>
          <BootstrapSmartTable
            name={tableName}
            columns={columns}
            data={referrals}
            defaultFilters={[['taskStatus', 'PENDING']]}
            showFilter
            isLoading={loading}
            defaultSortKey={['taskStatus', 'descending']}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default ReferralsTable;
