import {createGlobalState} from "react-global-hooks";
import {useEffect} from "react";
import {useRecoilValue} from "recoil";
import configurationAtom from "@state/recoil/configurationAtom";
import {useJsApiLoader} from "@react-google-maps/api";

export const mapLoaderState = createGlobalState(false as boolean);
const libraries: ("places" | "geometry" | "drawing" | "localContext" | "visualization")[] = ["places", "geometry"];

export const MapLoader = () => {
  const configuration = useRecoilValue(configurationAtom);
  const {isLoaded} = useJsApiLoader({
    id: `hornet-googles-map`,
    googleMapsApiKey: configuration?.google_maps_key || '',
    libraries
  });

  useEffect(() => {
    if (isLoaded) {
      mapLoaderState.set(isLoaded);
    }
  }, [isLoaded]);

  return null;
}
