import React, {useEffect, useRef, useState} from 'react';
import hornetCapitalLogo from '@assets/images/hornet_logo_regular.svg';
import useVerify2faUser from "@legacy/views/auth/TwoFactorAuthView/useVerify2faUser";
import {getTwoFactorAuthConfig} from "@hornet-api/twoFactorAuth/meta/getTwoFactorAuthConfig";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {alertApiErrors} from "@common/errors";
import {TwoFactorAuthAtLogin, TwoFactorAuthConfig} from "@interfaces/TwoFactorAuth";
import {SubmitHandler, useForm} from "react-hook-form";
import {TextBox} from "@components/forms/react-hook-form-bootstrap";
import {fetchUser2FA} from "@hornet-api/twoFactorAuth/meta/fetchUser2FA";
import LoadingWithWrapper from "@components/Indicators/LoadingWithWrapper";
import OtpResendWarning from "@legacy/views/auth/TwoFactorAuthView/common/OtpResendWarning";
import VerificationFailedWarning from "@legacy/views/auth/TwoFactorAuthView/common/VerificationFailedWarning";
import ResendOtpButton from "@legacy/views/auth/TwoFactorAuthView/common/ResendOtpButton";
import useLogoutHandler from "@legacy/views/auth/useLogoutHandler";
import resendOTP2FA from "@hornet-api/twoFactorAuth/meta/resendOTP2FA";
import {addAlert} from "@components/Alert";

interface Form2FA {
  otp2fa: string;
}

interface VerificationFailedResponse {
  message: string,
  twoFactorAuth: TwoFactorAuthAtLogin
}

const defaultForm2FAData: Form2FA = {
  otp2fa: ''
}

const TwoFactorAuthView = () => {
  const verify2faUser = useVerify2faUser();
  const [twoFactorAuthConfig, setTwoFactorAuthConfig] = useState<TwoFactorAuthConfig | null>();
  const [user2FA, setUser2FA] = useState<TwoFactorAuthAtLogin | null>();
  const [countdown, setCountdown] = useState<number>(0);
  const firstInputRef = useRef<HTMLInputElement>(null);
  const logoutHandler = useLogoutHandler();

  const {
    handleSubmit,
    control,
    reset,
    getValues,
  } = useForm<Form2FA>({defaultValues: defaultForm2FAData});

  const resetLockedForSeconds = (countdown: number) => {
    setCountdown(countdown)
    if (!countdown && user2FA) {
      setUser2FA({
        ...user2FA,
        resendLockedForSeconds: 0
      })
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (firstInputRef.current) firstInputRef.current.focus();
    }, 300);

    const t = loadingTrigger();
    Promise.all([
      getTwoFactorAuthConfig()
        .then((data) => {
          setTwoFactorAuthConfig(data)
        }),
      fetchUser2FA()
        .then((data) => {
          if (!data?.isEnabled) {
            logoutHandler();
            return;
          }
          setUser2FA(data)
        })
    ])
      .catch((error) => {
        if (error.response.status === 429) {
          logoutHandler()
          return;
        }
        alertApiErrors(error)
      })
      .finally(() => {
        loadingRelease(t)
      });
  }, []);

  const resendOtp = () => {
    if (countdown > 0) return;

    const t = loadingTrigger();
    resendOTP2FA()
      .then(async (twoFactorAuth) => {
        if (twoFactorAuth) {
          await setUser2FA(twoFactorAuth)
        }
        reset(getValues());
      })
      .catch((error) => {
        if (error.response.status === 429) {
          logoutHandler();
          return;
        }
        alertApiErrors(error);
      })
      .finally(() => {
        loadingRelease(t);
      });
  }

  const onSubmit: SubmitHandler<Form2FA> = (data) => {
    verify2faUser(data.otp2fa).then((response: unknown) => {
      if (response) {
        const data = response as VerificationFailedResponse
        setUser2FA(data.twoFactorAuth);
        addAlert({
          type: "danger",
          content: data.message,
        });
      }
    }).catch((error) => {
      if (error?.response?.status === 429) {
        addAlert({
          type: "danger",
          content: error.response.data
        })
        logoutHandler()
        return;
      }
      alertApiErrors(error)
    });
  }

  if (!twoFactorAuthConfig) {
    return null;
  }

  return (
    <div className="login-wrapper">
      <div className="login-area">
        <div className="login-logo">
          <img src={hornetCapitalLogo} alt="Hornet Capital"/>
        </div>
        <div className="card panel-custom">
          <div className="card-heading custom_head">
            <div className="view-header">
              <div className="header-title">
                <h3>Login</h3>
                <small>Please enter the Authorization Code you received on your phone.</small>
              </div>
            </div>
          </div>

          <div className="card-body">
            {
              (!twoFactorAuthConfig || !user2FA) && <LoadingWithWrapper/>
            }
            {
              twoFactorAuthConfig && user2FA &&
              (
                <form noValidate method="post" id="loginForm" onSubmit={handleSubmit(onSubmit)}>
                  <OtpResendWarning
                    maxResendsPerLock={twoFactorAuthConfig?.maxResendsPerLock}
                    resendLockHours={twoFactorAuthConfig?.resendLockHours}
                    resendCount={user2FA?.resendCount}
                  />
                  <TextBox
                    name={'otp2fa'}
                    label={'Please enter your Authorization Code'}
                    control={control}
                    type={"tel"}
                    ref={firstInputRef}
                    maxLength={twoFactorAuthConfig.codeLength}
                    rules={{
                      required: true,
                      minLength: {
                        value: twoFactorAuthConfig.codeLength,
                        message: `It should be a ${twoFactorAuthConfig.codeLength} digit number`
                      },
                      maxLength: {
                        value: twoFactorAuthConfig.codeLength,
                        message: `It should be a ${twoFactorAuthConfig.codeLength} digit number`
                      }
                    }}
                  />

                  <ResendOtpButton
                    onCountdownChange={resetLockedForSeconds}
                    resendLockedForSeconds={user2FA?.resendLockedForSeconds}
                    resendOtp={resendOtp}
                  />

                  <VerificationFailedWarning
                    failureCount={user2FA?.failureCount}
                    maxFailureCount={twoFactorAuthConfig?.maxFailureCount}
                  />

                  <div className="text-center">
                    <button
                      type="submit"
                      name="Verify"
                      className="btn btn-primary btn-block"
                    >Verify
                    </button>
                    <div>or</div>
                    <button
                      type="button"
                      name="Cancel"
                      onClick={logoutHandler}
                      className="btn btn-secondary btn-block"
                    >Cancel
                    </button>
                  </div>
                </form>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuthView;
