import React, {useEffect, useMemo, useState} from 'react';
import {createGlobalState} from 'react-global-hooks';
import BankAccountWithOwner from '@interfaces/BankAccountWithOwner';
import {Form, Modal, Row, Col, Button} from "react-bootstrap";
import {SubmitErrorHandler, SubmitHandler, useForm} from "react-hook-form";
import {SearchableSelect, Select, TextBox} from "@components/forms/react-hook-form-bootstrap";
import {
  convertServerDataToFormData,
  defaultBankAccount,
  getFormDataForEntity,
  IBankAccountForm
} from "@components/modals/AddEditBankModal/constant";
import getEntitiesListLight, {EntityListLightObj} from "@hornet-api/entities/getEntitiesListLight";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {alertApiErrors} from "@common/errors";
import {getFullName} from "@common/basic";
import {useRecoilValue} from "recoil";
import profileAtom from "@state/recoil/profileAtom";
import {BankAccountDepositMethodEnum, BankAccountTypeEnum} from "@interfaces/GeneratedEnums";
import updateBankAccountWithEntity from "@hornet-api/banking/updateBankAccountWithEntity";
import getBankAccount from "@hornet-api/banking/getBankAccount";
import decryptObject from "@common/decryptObject";
import {ContactBankAccountSubmit} from "@interfaces/BankAccount";
import {validateAccountNumber, validateRoutingNumber} from "@common/utils/validators";
import {onInvalidHandler} from "@components/forms/react-hook-form-bootstrap/utils";

interface IAddEditBankAccountProps {
  id?: number | null;
  entityId?: number;
  profileId?: number;
  isFundingAccount?: boolean;
  isACHBankEditing?: boolean;
  onComplete?: (bankAccount: BankAccountWithOwner) => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
}

export const addEditBankAccountModalState = createGlobalState(null as null | IAddEditBankAccountProps);

export const openAddEditBankAccountModal = (props: IAddEditBankAccountProps) => {
  addEditBankAccountModalState.set(props);
};

/**
 * User UI - Unified Add/Edit Bank Account Modal
 *
 * This modal is being used everywhere we add or edit Bank Account on the User side
 *
 * @constructor
 */
const AddEditBankAccountModal = () => {
  const [modalState, setModalState] = addEditBankAccountModalState.use();
  const [entities, setEntities] = useState<EntityListLightObj[]>([]);
  const profile = useRecoilValue(profileAtom);

  const editId = modalState?.id;
  const {
    control,
    handleSubmit,
    reset,
  } = useForm<IBankAccountForm>({defaultValues: defaultBankAccount});

  const entityOptions = useMemo(() => {
    return [{label: getFullName(profile)!, value: 0}]
      .concat(entities.map(({id, name}) => ({
        label: name,
        value: id
      })));
  }, [entities]);

  useEffect(() => {
    const t = loadingTrigger();

    getEntitiesListLight()
      .then(setEntities)
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  }, []);

  useEffect(() => {
    if (!editId) {
      reset(defaultBankAccount);
      return;
    }
    const t = loadingTrigger();
    getBankAccount(editId).then(ba => {
      const t2 = loadingTrigger();
      decryptObject(ba).then(bankAccount => {
        reset(convertServerDataToFormData(bankAccount, profile));
      }).finally(() => loadingRelease(t2));
    })
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  }, [editId]);

  useEffect(() => {
    if (!modalState?.entityId) return
    reset(getFormDataForEntity(modalState?.entityId, entities));
  }, [modalState?.entityId, entities]);

  const handleClose = () => {
    modalState?.onCancel && modalState.onCancel();
    setModalState(null)
    reset({});
  }

  const onFormSubmit: SubmitHandler<IBankAccountForm> = (data) => {
    const t = loadingTrigger();
    const serverReqData: ContactBankAccountSubmit = data;
    if (modalState?.isFundingAccount) {
      serverReqData.isVerified = false
    }
    updateBankAccountWithEntity(+data.entity?.value! || null, serverReqData)
      .then((bankAccount) => {
        modalState?.onComplete?.(bankAccount);
        handleClose();
      })
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  }
  const onInvalid: SubmitErrorHandler<IBankAccountForm> = async (errs) => {
    onInvalidHandler(errs)
  };
  return (
    <Modal
      show={!!modalState}
      onHide={handleClose}
      role={'document'}
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title>{editId ? 'Edit' : 'Create'} Bank Account</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit(onFormSubmit, onInvalid)}>
        <Modal.Body>
          {
            !modalState?.entityId && !modalState?.profileId && !modalState?.isACHBankEditing && (
              <SearchableSelect
                name={`entity`}
                label={'Entity'}
                control={control}
                options={entityOptions}
                disabled={!!editId}
                rules={{required: true}}
              />
            )
          }

          <TextBox
            name={`bankName`}
            label={'Bank Name'}
            control={control}
            rules={{required: true}}
          />

          <TextBox
            name={`nameOnAccount`}
            label={'Name on Account'}
            control={control}
            rules={{required: true}}
          />

          <Row>
            <Col>
              <Select
                name={`accountType`}
                label={'Account Type'}
                control={control}
                options={BankAccountTypeEnum}
                rules={{required: true}}
              />
            </Col>
            <Col>
              <Select
                name={`depositMethod`}
                label={'Deposit Method'}
                control={control}
                options={BankAccountDepositMethodEnum}
                rules={{required: true}}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <TextBox
                name={`accountInformation.accountNumber`}
                label={'Account #'}
                control={control}
                type={'number'}
                rules={{
                  required: true,
                  validate: validateAccountNumber
                }}
              />
            </Col>
            <Col>
              <TextBox
                name={`accountInformation.routingNumber`}
                label={'Routing #'}
                control={control}
                type={'number'}
                rules={{
                  required: true,
                  validate: validateRoutingNumber
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            variant="primary"
          >Save</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddEditBankAccountModal;
