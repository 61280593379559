import React from 'react';
import {Column} from './index';
import Store from './globalState';
import EditCell from './EditCell';
import style from './style.module.scss';
import {DecryptAll} from "./Types";
import _ from "lodash";
import classNames from "classnames";

type TableCellProps<D> = {
	colIndex: number,
	col: Column<D>
	row: D,
	store: Store<D>,
	xPos: number,
	isMobile?: boolean;
	isRemote?: boolean;
	refreshTableData?: () => void;
	decryptAll?: DecryptAll,
	removeBlankOption? : boolean,
}

export default function TableCell<D>(props: TableCellProps<D>) {
	const {store, colIndex, isRemote, refreshTableData, decryptAll} = props;
	const [hiddenColumns] = store.hiddenColumnsState.use();
	const [showActionBar] = store.showActionBarState.use();

	const {col, row, isMobile = false} = props;

	const getFieldValue = (field: string): string | number | null => {
		if (!_.hasIn(row, field)) {
			return null
		}
		const fieldValue = _.get(row, field)
		return col.lookup && `${fieldValue}` in col.lookup ?
			col.lookup[`${fieldValue}`]
			: fieldValue
	}
	const getColText = () => {
		const fieldValue = getFieldValue(col.field);
		return col.render ?
			col.render(row, decryptAll, refreshTableData)
			: (
				fieldValue ? `${fieldValue}` : null
			);
	};

	if (!(hiddenColumns.includes(colIndex)) || col.actionBar) {
		let className = 'editable-col';
		if (col.truncate)
			className += ' truncate';

		if (col.oneLine)
			className += ' one-line';

		const cellTitle = col.field ?
			(
				(col.field as string).split('|').map(cf => {
					const fieldValue = getFieldValue(cf)
					return fieldValue ? `${fieldValue}` : null
				}).filter(x => x).join(' | ')
			)
			: null;

		if (col.actionBar && !isMobile) {
			return (
				<td className={style.actionCell} width={0}>
					<div className={showActionBar ? style.actionBar : classNames(style.actionBar, style.hiddenActionBar)}>
						{getColText()}
					</div>
				</td>
			);
		}

		let isEditCell = false;
		if (col.onEdit && (!col.canEdit || col.canEdit(row))) {
			isEditCell = true;
		}
		if (col.editLock !== undefined && col.editLock(row)) {
			isEditCell = false;
		}

		return (
			<td
				className={className}
				style={{textAlign: col.align ? col.align : 'left'}}
				title={`${cellTitle}`}
			>
				{
					isEditCell ?
						<EditCell
							col={col}
							row={row}
							xPos={props.xPos}
							isRemote={isRemote}
							refreshTableData={refreshTableData}
						>
							{getColText()}
						</EditCell>
						:
						getColText()
				}
			</td>
		);
	}
	return null;

}
