/* eslint-disable no-magic-numbers */
import React, {useEffect, useLayoutEffect} from 'react';
import Navigation from '@components/Navigation';
import NotificationSidebar from '@components/NotificationSidebar';
import {MapLoader} from "@components/map";
import LiveChatMobile from "@components/NotificationSidebar/LiveChatMobile";
import {refreshUserBudgetNotificationCount} from "@components/NotificationSidebar/utils";
import DashboardAccessMonitor from "@controls/monitors/DashboardAccessMonitor";
import TwoFactorAuthCheck from "@legacy/views/UserProfileLegacy/TwoFactorAuthCheck";
import getDashboards from "@hornet-api/reports/getDashboards";
import {
	announcementsState,
	fetchOnlyUnreadState,
	isLoadingAnnouncementsState,
	refreshAnnouncementsState,
	reportsState
} from "@components/NotificationSidebar/globalState";
import {useRecoilValue} from "recoil";
import profileAtom from "@state/recoil/profileAtom";
import {maxDropdownHeightState, navDropDownState} from "@components/Navigation/Desktop/NavigationMenuRenderer";
import getAnnouncements from "@hornet-api/chat/getAnnouncements";
import activeRoleState from "@state/globalState/activeRoleState";

const UserLandLayoutLegacy = ({children}) => {
	const profile = useRecoilValue(profileAtom);

	const navDropDownStateValue = navDropDownState.useValue();
	const maxDropdownHeight = maxDropdownHeightState.useValue();
	const fetchOnlyUnread = fetchOnlyUnreadState.useValue();
	const refreshAnnouncementsParam = refreshAnnouncementsState.useValue();
	const activeRole = activeRoleState.useValue();

	useEffect(() => {
		isLoadingAnnouncementsState.set(true);
		getAnnouncements(fetchOnlyUnread)
			.then(announcementsState.set)
			.finally(() => isLoadingAnnouncementsState.set(false));
	}, [refreshAnnouncementsParam, activeRole, fetchOnlyUnread]);

	useEffect(() => {
		refreshUserBudgetNotificationCount();
		// your function that refreshes the reports
		getDashboards()
			.then(reports => {
				// You can use the 'reports' data here.
				reportsState.set(reports);
			})
			.catch(error => {
				console.error(error)
				// Handle any errors here.
			});
	}, [])
	useLayoutEffect(() => {
		const bodyClasses = [
			'hold-transition',
			'sidebar-mini',
			'role-user',
		];
		document.body.classList.add(...bodyClasses);
		return () => {
			document.body.classList.remove(...bodyClasses);
		};
	}, []);

	return (
		<div className="wrapper">
			<Navigation/>
			<div
				id="main-container" className="main-container"
				style={{minHeight: `${navDropDownStateValue.length && maxDropdownHeight}px`}}
			>
				<div className={`container-fluid navbar-space ${navDropDownStateValue.length ? 'mt-4' : ''}`}>
					{children}
				</div>
			</div>
			<NotificationSidebar/>
			{/** Show Chat Only if User is Present for contact **/}
			{profile?.hasUser && <LiveChatMobile/>}
			<DashboardAccessMonitor/>
			<TwoFactorAuthCheck/>
			<MapLoader/>
		</div>
	);
};

export default UserLandLayoutLegacy;
