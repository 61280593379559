import {SmartAddressProps} from "@components/forms/react-hook-form-bootstrap/interfaces";
import configurationState from "@state/globalState/configurationState";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {statesTitleCase as statesObject} from "@common/states";
import {Hidden, Select, TextBox} from "@components/forms/react-hook-form-bootstrap/react-hook-form-bootstrap";
import {AddressTypeEnum} from "@interfaces/GeneratedEnums";
import {useLoadScript} from "@react-google-maps/api";
import {generateSearchRegExp} from "@common/utils/validators/globalSearchValidator";
import {FaMapMarkerAlt} from "react-icons/fa";
import hornetCapitalSmallLOG from '@assets/images/logo16_16.png';
import {zipValidator} from "@common/utils/validators/zipValidator";

const libraries: ('places')[] = ['places'];

export interface AddressOption {
  localDbId: string
  addressArray: string[]
  fullAddress: string
  counties: number[]
}

interface PlacePrediction {
  description: string;
  place_id: string;
  localDbId?: string
}


export const HornetSmartAddress = (
  {
    name,
    rules,
    showAddressType = false,
    label,
    control,
    defaultValue,
    getDefaultFunction,
    disabled = false,
    setValue,
    trigger,
    addressOptions,
    onSelectAddressOptions
  }: SmartAddressProps
) => {
  const configuration = configurationState.useValue();
  const [suggestions, setSuggestions] = useState<PlacePrediction[]>([]);
  const [filterAddressOptions, setFilterAddressOptions] = useState<AddressOption[]>([]);

  const autocomplete = new window.google.maps.places.AutocompleteService();
  const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
  const ref = useRef<HTMLInputElement>(null);

  const filteredAddresses = useCallback((value: string) => {
    if (!addressOptions) return []
    const {regExp, regExpReverse} = generateSearchRegExp(value);
    return addressOptions.filter(option => {
      return (option.fullAddress.match(regExp) || option.fullAddress.match(regExpReverse));
    }).slice(0, 20);
  }, [addressOptions]);

  const onDbPlaceSelected = (dbPlace: AddressOption) => {
    if (onSelectAddressOptions) onSelectAddressOptions(dbPlace);
    if (!setValue) return;
    const {addressArray, fullAddress,localDbId} = dbPlace;
    setValue(`${name}.hornetPropertyId`, localDbId);
    setValue(`${name}.search`, fullAddress);
    setValue(`${name}.address1`, addressArray[0]);
    setValue(`${name}.address2`, addressArray[1]);
    setValue(`${name}.city`, addressArray[2]);
    setValue(`${name}.state`, addressArray[3]);
    setValue(`${name}.zip`, addressArray[4]);
    setValue(`${name}.latitude`, addressArray[5]);
    setValue(`${name}.longitude`, addressArray[6]);
    if (trigger) {
      [`${name}.address1`, `${name}.city`, `${name}.state`, `${name}.zip`, `${name}.latitude`, `${name}.longitude`].forEach((_name) => trigger(_name))
    }
  }

  const onPlaceSelected = (place: google.maps.places.PlaceResult) => {
    if (!setValue) return;
    onChange()
    const streetNumber = place.address_components?.find((x) =>
      x.types.includes('street_number')
    )?.long_name;
    const streetName = place.address_components?.find((x) =>
      x.types.includes('route')
    )?.long_name;
    const address1 = `${streetNumber ?? ''} ${streetName ?? ''}`;
    setValue(`${name}.address1`, address1);

    const city = place.address_components?.find((x) => x.types.includes('locality'))?.long_name;
    setValue(`${name}.city`, city);

    const state = place.address_components?.find((x) =>
      x.types.includes('administrative_area_level_1')
    )?.short_name;
    setValue(`${name}.state`, state);

    const zip = place.address_components?.find((x) =>
      x.types.includes('postal_code')
    )?.short_name;
    setValue(`${name}.zip`, zip);

    const latitude = place.geometry?.location?.lat();
    const longitude = place.geometry?.location?.lng();
    if (longitude && latitude) {
      setValue(`${name}.latitude`, latitude);
      setValue(`${name}.longitude`, longitude);
    }
    if (trigger) {
      [`${name}.address1`, `${name}.city`, `${name}.state`, `${name}.zip`].forEach((_name) => trigger(_name))
      if (longitude && latitude) {
        trigger(`${name}.latitude`);
        trigger(`${name}.longitude`);
      }
    }
  }

  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: configuration?.google_maps_key || "",
    libraries
  });

  const handleInput = (value: string) => {
    // Trigger autocomplete API call to get suggestions
    if (value) {
      setFilterAddressOptions(filteredAddresses(value));
      autocomplete.getPlacePredictions({input: value}, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          setSuggestions(predictions);
        } else {
          setSuggestions([]);
        }
      });
    } else {
      setFilterAddressOptions([]);
      setSuggestions([]);
    }
  };


  const handleSelect = (place: PlacePrediction) => {
    setFilterAddressOptions([]);
    setSuggestions([]);
    setValue && setValue(`${name}.search`, place.description);
    placesService.getDetails(
      {placeId: place.place_id, fields: ['geometry.location', 'address_components']},
      (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && result) {
          onPlaceSelected(result)
        }
      });
  };
  const handleSelectLocalAddress = (place: AddressOption) => {
    setFilterAddressOptions([]);
    setSuggestions([]);
    onDbPlaceSelected(place)
  };


  const dv = useMemo(() => {
    return getDefaultFunction ? getDefaultFunction(name, defaultValue) : defaultValue;
  }, [getDefaultFunction, defaultValue]);

  const onChange = useCallback(() => {
    setValue?.(`${name}.hornetPropertyId`, '');
    onSelectAddressOptions?.(null);
  }, [setValue, name, onSelectAddressOptions]);

  // prevent submitting form on enter
  useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      } else if (event.key === 'Escape') {
        setFilterAddressOptions([]);
        setSuggestions([]);
      }
    };
    if (ref.current) {
      (ref.current as HTMLInputElement).addEventListener('keydown', callback);
    }
    return () => {
      try {
        if (ref.current) (ref.current as HTMLInputElement).removeEventListener('keydown', callback);
      } catch (e) {
      }
    };
  }, [ref.current]);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading Maps...</div>;
  }
  return (
    <div className={'smart-address'} style={{position: 'relative'}}>
      <strong>{label}</strong>
      <TextBox
        control={control}
        disabled={disabled}
        label={'Address Search'}
        name={`${name}.search`} ref={ref}
        onChange={handleInput}
      />
      {(filterAddressOptions.length > 0
          || suggestions.length > 0) &&
          <ul className="suggestions-list">
            {filterAddressOptions.length > 0 &&
                <>
                  {filterAddressOptions.map((localAddress) => (
                    <li key={localAddress.localDbId}
                        onClick={() => handleSelectLocalAddress(localAddress)}>
                      <img
                        alt='' src={hornetCapitalSmallLOG}
                      /> {localAddress.fullAddress}
                    </li>
                  ))}
                    <hr/>
                </>}
            {suggestions.length > 0 &&
              suggestions.map((suggestion) => (
                <li key={suggestion.place_id} className={'danger'}
                    onClick={() => handleSelect(suggestion)}>
                  <FaMapMarkerAlt/>{suggestion.description}
                </li>))
            }
          </ul>
      }
      <Hidden
        name={`${name}.hornetPropertyId`}
        control={control}
        disabled={disabled}
        getDefaultFunction={getDefaultFunction}
      />
      <Hidden
        name={`${name}.id`}
        control={control}
        disabled={disabled}
        defaultValue={dv?.id ?? undefined}
        getDefaultFunction={getDefaultFunction}
      />
      {showAddressType ?
        <Select
          name={`${name}.addressType`}
          label={'Address Type'}
          control={control}
          rules={rules}
          disabled={disabled}
          options={AddressTypeEnum}
          defaultValue={dv?.addressType ?? 'PHYSICAL'}
          onChange={onChange}
          getDefaultFunction={getDefaultFunction}/>
        : <Hidden
          name={`${name}.addressType`}
          control={control}
          rules={rules}
          disabled={disabled}
          onChange={onChange}
          defaultValue={dv?.addressType ?? 'PHYSICAL'}
          getDefaultFunction={getDefaultFunction}
        />}
      <Hidden
        name={`${name}.addressType`}
        control={control}
        rules={rules}
        disabled={disabled}
        onChange={onChange}
        defaultValue={dv?.addressType ?? 'PHYSICAL'}
        getDefaultFunction={getDefaultFunction}
      />
      <TextBox
        name={`${name}.address1`}
        label={'Street Address'}
        type={'string'}
        control={control}
        rules={rules}
        disabled={disabled}
        onChange={onChange}
        defaultValue={dv?.address1 ?? undefined}
        getDefaultFunction={getDefaultFunction}
      />

      <TextBox
        name={`${name}.address2`}
        label={'Address Line 2'}
        type={'string'}
        control={control}
        disabled={disabled}
        onChange={onChange}
        defaultValue={dv?.address2 ?? undefined}
        getDefaultFunction={getDefaultFunction}
      />

      <Row>
        <Col>
          <TextBox
            name={`${name}.city`}
            label={'City'}
            type={'string'}
            control={control}
            rules={rules}
            disabled={disabled}
            onChange={onChange}
            defaultValue={dv?.city ?? undefined}
            getDefaultFunction={getDefaultFunction}
          />
        </Col>

        <Col>
          <Select
            name={`${name}.state`}
            label={'State'}
            control={control}
            rules={rules}
            options={statesObject}
            disabled={disabled}
            defaultValue={dv?.state ?? undefined}
            onChange={onChange}
            getDefaultFunction={getDefaultFunction}
          />
        </Col>
        <Col>
          <TextBox
            name={`${name}.zip`}
            label={'Zip'}
            type={'zip'}
            control={control}
            rules={{
              ...rules,
              validate: {
                ...rules?.validate,
                zipValidation: zipValidator
              }
            }}
            disabled={disabled}
            onChange={onChange}
            defaultValue={dv?.zip ?? undefined}
            getDefaultFunction={getDefaultFunction}
          />
        </Col>
      </Row>
    </div>);
};