import React, {useEffect, useRef, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {createGlobalState} from 'react-global-hooks';
import axios from "axios";
import {downloadFile} from "@common/utils/fileDownloader";

import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {addAlert} from "@components/Alert";
import {alertDownloadDocError} from "@common/errors";
import './ShowDocumentModal.scss'

interface ShowDocumentModalState {
  url: string;
  name: string;
}

const showDocumentModalState = createGlobalState(null as null | ShowDocumentModalState);

const allowedContentTypes = ['application/pdf', 'image', 'text/html', 'text/plain']

export const openShowDocumentModal = (modalState: ShowDocumentModalState) => {
  showDocumentModalState.set({
    ...modalState
  });
};

const ShowDocumentModal = () => {
  const iframeRef = useRef<HTMLIFrameElement | null>();
  const [modalState, setModalState] = showDocumentModalState.use();
  const [fileContentUrl, setFileContentUrl] = useState('');
  const documentUrl = modalState?.url;
  const documentName = modalState?.name;

  useEffect(() => {
    if (documentUrl && documentName) {
      setFileContentUrl('');
      const t = loadingTrigger();
      axios.get(documentUrl, {
        responseType: 'blob'
      })
        .then((response) => {
          if (allowedContentTypes.some((type) => response.headers["content-type"].startsWith(type))) {
            setFileContentUrl(window.URL.createObjectURL(new Blob([response.data as BlobPart],
              {type: response.headers["content-type"]})));
            return;
          }

          handleClose();

          if (!confirm('This file can not be viewed. Would you like to download the file?')) {
            return;
          }

          const downloadResponse = downloadFile(response, documentName);

          if (downloadResponse === true) {
            addAlert({
              type: "success",
              content: "File downloaded successfully."
            })
          } else {
            addAlert({
              type: "danger",
              content: "File is not viewable or downloadable. Please contact administrator."
            })
            console.error('Response while viewing file: ', response);
          }
        })
        .catch(async (error) => {
          await alertDownloadDocError(error);
          handleClose();
        })
        .finally(() => loadingRelease(t));
    }
  }, [documentUrl, documentName])

  const handleClose = () => {
    setModalState(null);
  };

  return (
    <Modal
      show={!!modalState}
      onHide={handleClose}
      id={'showDocumentModal'}
      size={'xl'}
      className={'view-file-modal'}
    >
      <Modal.Header closeButton>
        <Modal.Title>Document: {documentName}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'position-relative'}>
        {
          fileContentUrl &&
          (
            <iframe
              className={'file-view-iframe'}
              ref={(ref) => iframeRef.current = ref}
              src={fileContentUrl}
              title={documentName}
            />
          )
        }
      </Modal.Body>
    </Modal>
  );
};

export default ShowDocumentModal;
