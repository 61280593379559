import axios from 'axios';
import {getTaskUrl} from './util';
import {Task} from '@interfaces/task';

export type GetTaskOptions = {
  taskId: number,
  contactId?: number,
  companyId?: number,
  contactCompanyId?: number,
}
const getTask = async ({
  taskId,
  contactId,
  companyId,
  contactCompanyId,
}: GetTaskOptions) => {
  let url = getTaskUrl();
  if (companyId) {
    url += `/entity/${companyId}/task/${taskId}`;
  } else if (contactId) {
    url += `/contact/${contactId}/task/${taskId}`;
  } else if (contactCompanyId){
    url += `/contact/entity/${contactCompanyId}/task/${taskId}`;
  } else {
    url += `/contact/task/${taskId}`;
  }

  const response = await axios.get(url);
  // @ts-ignore
  return response.data as Task;
};

export default getTask;
