import {v4 as getUuid} from 'uuid';
import {createGlobalState} from 'react-global-hooks';
import axios from 'axios';
import getMetaApiPath from '@hornet-api/getMetaApiPath';
import {getAccessToken} from "@common/token";

export const usersThatCanSavePresets = [];
export const rolesThatCanSavePresets = ['ROLE_SUPERADMIN', 'ROLE_ADMIN'];
// set this on loading both admin and client (they work differently). Put in headers
export const canSavePresetsState = createGlobalState(false);

export const getPathname = () => {
  const actions = ['create', 'edit', 'show'];
  let pathname = window.location.pathname;
  if (pathname.startsWith('/legacy/')) {
    return pathname;
  }

  const foundActionIndex = pathname.search(new RegExp(`/${actions.join('|/')}`));
  return foundActionIndex > -1 ? pathname.substring(0, pathname.search(new RegExp(`/${actions.join('|/')}`))) : pathname;
}

const getKey = (tableName: string) => {
  const cleanTableName = tableName.split(' ').join('_');
  return btoa(`bstp_${window.location.host}_${window.location.port}_${window.location.pathname}_${cleanTableName}`);
}

const sortPresets = (presets: Preset[]) => {
  const newPresets = [...presets];
  newPresets.sort((a, b) => {
    let fa = a.name.toLowerCase(),
      fb = b.name.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  return newPresets;
}

export type Preset = {
  id: string, // uuid
  name: string,
  settings: any
}

export type PresetObject = {
  keyName: string,
  settings: string
}

const getPresetObject = (
  tableName: string
): Promise<PresetObject | null> => {
  return new Promise((resolve, reject) => {
    let key = getKey(tableName);
    if (!getAccessToken()) {
      resolve(null);
      return;
    }
    axios({
      method: 'get',
      url: `${getMetaApiPath()}/tablePreset/${key}`
    }).then((response) => {
      try {
        //@ts-ignore
        resolve(response.data);
      } catch (e) {
        console.error('getPresets: error', e);
        resolve(null);
      }

    }).catch((error) => {
      resolve(null);
    });
  })
}

const updatePresetObject = (
  tableName: string,
  presets: Preset[]
): Promise<PresetObject | null> => {
  console.log('updatePresetObject');
  return new Promise((resolve, reject) => {
    let key = getKey(tableName);
    if (!getAccessToken()) {
      resolve(null);
      return;
    }
    axios({
      method: 'put',
      url: `${getMetaApiPath()}/tablePreset`,
      data: {
        keyName: key,
        settings: JSON.stringify(presets)
      }
    }).then((response) => {
      try {
        //@ts-ignore
        resolve(response.data);
      } catch (e) {
        resolve(null);
      }
    }).catch((error) => {
      resolve(null);
    });
  })
}

const createPresetObject = (
  tableName: string,
  presets: Preset[]
): Promise<PresetObject | null> => {
  console.log('createPresetObject');
  return new Promise((resolve, reject) => {
    let key = getKey(tableName);
    if (!getAccessToken()) {
      resolve(null);
      return;
    }
    axios({
      method: 'post',
      url: `${getMetaApiPath()}/tablePreset`,
      data: {
        keyName: key,
        settings: JSON.stringify(presets)
      }
    }).then((response) => {
      console.log('createPresetObject', response.data)
      try {
        //@ts-ignore
        resolve(response.data);
      } catch (e) {
        resolve(null);
      }
    }).catch((error) => {
      resolve(null);
    });
  })
}

export const getPresets = (
  tableName: string
): Promise<Preset[]> => {
  return new Promise(async (resolve, reject) => {
    const presetObject = await getPresetObject(tableName);
    if (presetObject) {
      resolve(sortPresets(JSON.parse(presetObject.settings)));
    } else {
      resolve([]);
    }
  });
}

export const createPreset = (
  name: string,
  settings: any,
  tableName: string
): Promise<[Preset[], string]> => {
  console.log('createPreset');
  return new Promise(async (resolve, reject) => {
    const uuid = getUuid();
    const stored = await getPresetObject(tableName);
    if (stored) {
      try {
        const presets = JSON.parse(stored.settings) as Preset[];
        presets.push({
          id: uuid,
          name: name,
          settings: settings
        });
        await updatePresetObject(tableName, presets);
        resolve([presets, uuid]);
      } catch (e) {
        reject(e);
      }
    } else {
      // no existing presets exist for table, create one
      const presets = [{
        id: uuid,
        name: name,
        settings: settings
      }];
      await createPresetObject(tableName, presets);
      resolve([presets, uuid]);
    }
  })
};

export const updatePreset = (
  preset: Preset,
  tableName: string
): Promise<[Preset[], string]> => {
  console.log('updatePreset', {
    preset: preset,
    tableName: tableName
  });
  return new Promise(async (resolve, reject) => {
    const stored = await getPresetObject(tableName);
    if (stored) {
      try {
        const presets = JSON.parse(stored.settings) as Preset[];
        const presetIndex = presets.findIndex(x => x.id === preset.id);
        if (presetIndex >= 0) {
          presets[presetIndex] = preset;
        } else {
          // just add the preset
          presets.push(preset);
        }
        await updatePresetObject(tableName, presets);
        resolve([presets, preset.id]);
      } catch (e) {
        reject(e);
      }
    } else {
      // no existing presets exist for table, create one
      const presets = [preset];
      await createPresetObject(tableName, presets);
      resolve([presets, preset.id]);
    }
  })
};

export const deletePreset = (
  presetId: string,
  tableName: string,
): Promise<Preset[]> => {
  return new Promise(async (resolve, reject) => {
    let key = getKey(tableName);
    const stored = await getPresetObject(tableName);
    if (stored) {
      try {
        let presets = JSON.parse(stored.settings) as Preset[];
        presets = presets.filter(x => x.id !== presetId);
        await updatePresetObject(tableName, presets);;
        resolve(presets);
      } catch (e) {
        reject(e);
      }
    } else {
      throw 'Cannot get preset';
    }
  })
}

