/* eslint-disable curly */
/* eslint-disable no-magic-numbers */
import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {GoogleMap, Marker} from "@react-google-maps/api";
import {mapLoaderState} from "@components/map";

const defaultLatLng = {lat: 39.33809297782211, lng: -95.53241097421876};

const defaultContainerStyle = {
	width: '100%', height: '400px'
};
const ZOOM_MAP = {
	MIN: 8, MID: 11, MAX: 18
}

const UserLoanCollateralPropertyMap = ({property, containerStyle, updateMapLocation}) => {
	const isLoaded = mapLoaderState.useValue();
	const [map, setMap] = useState(null);
	const [zoom, setZoom] = useState(3);
	const [latitudeLocal, setLatitudeLocal] = useState();
	const [longitudeLocal, setLongitudeLocal] = useState();
	const [label, setLabel] = useState("");
	const {address1, state, city, zip, latitude, longitude} = property.mailingAddress;

	const appendStringQuery = (text, stringQuery) => {
		return `${text} ${stringQuery} `;
	}
	const findPlace = (queryText, fallback) => {
		const request = {
			query: queryText, fields: ['geometry.location']
		};
		const placesService = new window.google.maps.places.PlacesService(map);
		placesService.findPlaceFromQuery(request, (results, status) => {
			if (results && status !== 'ZERO_RESULTS') {
				setLatitudeLocal(results[0].geometry.location.lat());
				setLongitudeLocal(results[0].geometry.location.lng());
				if (fallback) {
					setZoom(ZOOM_MAP.MAX);
				}
			} else if (fallback) {
				fallback()
			}
		});
	}


	const fallbackQuery = () => {
		const {state, city, zip, county} = property.mailingAddress;
		const defaultCountry = 'United States';
		let fallbackStringQuery = '';
		if (county && county !== '') {
			fallbackStringQuery = appendStringQuery(county, fallbackStringQuery);
			setZoom(ZOOM_MAP.MIN);
		}
		if (state && state !== 'null') {
			fallbackStringQuery = appendStringQuery(`${state},`, fallbackStringQuery);
			setZoom(ZOOM_MAP.MIN);
		}
		if (zip) {
			fallbackStringQuery = appendStringQuery(`${zip} `, fallbackStringQuery);
			setZoom(ZOOM_MAP.MID);
		}
		if (city) {
			fallbackStringQuery = appendStringQuery(`${city} `, fallbackStringQuery);
			setZoom(ZOOM_MAP.MID);
		}
		if (fallbackStringQuery.length > 0) {
			findPlace(` ${fallbackStringQuery} ${defaultCountry}`);
		}
	};

	useEffect(() => {
		if (!map) {
			return;
		}

		if (latitude && longitude && !isNaN(longitude) && !isNaN(latitude)) {
			setLatitudeLocal(Number(latitude));
			setLongitudeLocal(Number(longitude));
			setZoom(ZOOM_MAP.MAX);
		} else if (address1 || (state && state !== 'null') || city) {
			findPlace(`${address1} ${zip}`, fallbackQuery);
		}
		if (address1 || zip) {
			setLabel(`${address1 || ''} ${zip || ''}`);
		}
	}, [map, address1, state, city, zip, latitude, longitude]);


	const center = useMemo(() => {
		if (latitudeLocal && longitudeLocal) {
			return {
				lat: latitudeLocal, lng: longitudeLocal
			};
		} else {
			return defaultLatLng;
		}
	}, [latitudeLocal, longitudeLocal]);

	const onUnmount = useCallback(function callback() {
		setMap(null);
	}, []);

	return (<Fragment>
		<div>
			{isLoaded && <GoogleMap
				mapContainerStyle={containerStyle || defaultContainerStyle}
				center={center}
				zoom={zoom}
				onLoad={(map) => {
					setMap(map)
				}}
				onUnmount={onUnmount}
			>
				{latitudeLocal && longitudeLocal &&
					<Marker
						position={{
							lat: latitudeLocal, lng: longitudeLocal
						}}
						draggable={!!updateMapLocation}
						onDragEnd={(event) => {
							updateMapLocation && updateMapLocation({
								latitude: event.latLng.lat(),
								longitude: event.latLng.lng()
							});
						}}
						label={label}
					/>}
			</GoogleMap>}
		</div>
	</Fragment>);
};

export default UserLoanCollateralPropertyMap;
