import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from 'react-router-dom';

import NotFoundView from '@views/NotFoundView/NotFoundView';
import ErrorTest from '@views/ErrorTest';
import UserLandLayoutViewLegacy from '@legacy-views/Layout/UserLandLayoutLegacy';
import makeRoutePath from './makeRoutePath';
import DashboardViewLegacy from '@legacy-views/DashboardViewLegacy';
import UserLoanView from '@legacy-views/UserLoanView';
import CreateInvestmentView from '@legacy-views/CreateInvestmentView';
import MyLoans from '@legacy-views/MyLoans';
import MyLoansBorrower from '@legacy-views/MyLoansBorrower';
import LoansAvailable from '@legacy-views/LoansAvailable';
import InvestmentListViewLegacy from '@legacy-views/InvestmentListViewLegacy';
import InvestmentDetailViewLegacy from '@legacy-views/InvestmentDetailViewLegacy';
import UserProfileLegacy from '@legacy-views/UserProfileLegacy';
import UserDocument from '@legacy-views/UserDocument';
import ESignature from '@pages/ESignature';
import BorrowerPrequalificationForm from "@views/BorrowerPrequalificationForm";
import BorrowerProjectApplication from "@views/BorrowerProjectApplication";
import HomeView from "@legacy/views/HomeView";
import RerouteToLegacy from '@components/RerouteToLegacy';
import NdaRoadblock from "@views/NdaRoadblock";

import EntityView from '@views/EntityView';

import CreateInvestmentAccount from '@legacy-views/CreateInvestmentAccount';
import {FundDetailView, FundsListView} from '@pages/funds';
import {LoanParticipationView, LoansListView} from '@pages/loans';
import styles from './RoutesUser.module.scss';

import LoadingTestPage from '@pages/LoadingTestPage';
import RoleRedirect from "@routes/RoleRedirect";
import BankAccountsPage from "@pages/BankAccountsPage";
import LogoutView from "@legacy/views/auth/LogoutView";
import BorrowerProjectApplicationView from "@views/BorrowerProjectApplicationView";
import hasAuthority from "@common/hasAuthority";
import activeRoleState from "@state/globalState/activeRoleState";
import userRoleGroupsState from "@state/globalState/userRoleGroupsState";
import TasksView from "@legacy/views/TasksView";
import MyLoansEscrowOfficer from "@legacy/views/MyLoansEscrowOfficer";
import ReferralLandingHome from "@legacy/../ui/views/ReferralLandingHome";
import isReferralRoadblockedSelector from "@state/globalState/isReferralRoadblockedSelector";
import AmbassadorProgram from "@views/AmbassadorProgram";
import UserLoanPayments from "@legacy/views/UserLoanPaymentsView";
import RedirectLocalStorage from "@routes/RedirectLocalStorage";
import ESignRequest from "@views/ESignRequest";
import TransactionsPage from "@pages/TransactionsPage";
import BpaSign from "@views/BpaSign";
import UserBudget from "src/ui-legacy/views/UserBudget";
import MyLoanLender from "@legacy/views/MyLoanLender";
import FundMap from "@legacy/views/FundMapView/FundMap";
import RecurringLoanPayments from "@legacy/views/RecurringPaymentsView/RecurringLoanPayments";
import RecurringInvestments from "@legacy/views/RecurringPaymentsView/RecurringInvestments";
import AnnouncementView from "@legacy/views/AnnouncementsView/AnnouncementView";
import {FcmMessagingComponent} from "@components/FcmMessagingComponent";
import UserPayoffRequest from "@legacy/views/UserLoanView/UserPayoffRequest";

interface IRoleMap {
  borrowers: string[];
  investors: string[];
  accreditedInvestors: string[];
  escrowOfficers: string[];
  lenders: string[]
}

const role: IRoleMap = {
  borrowers: ['ROLE_BORROWER'],
  investors: ['ROLE_NON_ACCREDITED_INVESTOR', 'ROLE_ACCREDITED_INVESTOR'],
  accreditedInvestors: ['ROLE_ACCREDITED_INVESTOR'],
  escrowOfficers: ['ROLE_USER_SEARCHABLE_AS_ESCROW_OFFICER'],
  lenders: ['ROLE_LENDER']
}

const Page = (props: any) => {
  useEffect(() => {
    document.title = props.title || 'Hornet Capital'
  }, [props.title])
  return props.children
}

const RoutesUser = () => {
  const {url} = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const activeRole = activeRoleState.useValue();
  const userRoleGroups = userRoleGroupsState.useValue();
  const isReferralRoadblocked = isReferralRoadblockedSelector();
  // handle redirect to referral page if needed
  useEffect(() => {
    if (isReferralRoadblocked && !location.pathname.startsWith('/referral')) {
      history.push(`/referral`);
    }

  }, [isReferralRoadblocked, location]);

  return (
    <UserLandLayoutViewLegacy>
      <RedirectLocalStorage/>
      <div className={styles.container}>
        <Switch location={location}>
          <Route path={makeRoutePath(url, '/logout')} exact><LogoutView/></Route>
          <Route path={makeRoutePath(url, '/')}
                 render={(props) => (
                   <Page title="Hornet Capital - Home">
                     <HomeView {...props} />
                   </Page>
                 )}
                 exact
          />
          <Route
            path={makeRoutePath(url, '/dashboard/:id?')}
            exact
            render={() => (
              <Page title="Hornet Capital - Dashboard">
                <RoleRedirect roles={role.investors}>
                  <NdaRoadblock><DashboardViewLegacy/></NdaRoadblock>
                </RoleRedirect>
              </Page>
            )
            }
          />
          <Route
            path={makeRoutePath(url, '/referral')}
            exact
            render={() => (
              <Page title="Hornet Capital - Referral">
                <ReferralLandingHome/>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/investor/show/:investmentAccountId(\\d+)')}
            exact
            render={(props) => (
              <Page title="Hornet Capital - Investments">
                <RoleRedirect roles={role.investors}>
                  <NdaRoadblock><InvestmentDetailViewLegacy {...props}/></NdaRoadblock>
                </RoleRedirect>
              </Page>
            )}
          />
            <Route
                path={makeRoutePath(url, '/fundMap')}
                exact
                render={() => (
                    <Page title="Hornet Capital - Fund Map">
                        <FundMap/>
                    </Page>
                )}
            />
          <Route
            path={makeRoutePath(url, '/userContact/investor')}
            exact
            render={(props) => (
              <Page title="Hornet Capital - Investments">
                <RoleRedirect roles={role.investors}>
                  <NdaRoadblock><InvestmentListViewLegacy {...props}/></NdaRoadblock>
                </RoleRedirect>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/userContact/documents')}
            exact
            render={() => (
              <Page title="Hornet Capital - Documents">
                <UserDocument/>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/userContact/createInvestment')}
            exact
            render={() => (
              <Page title="Hornet Capital - Create Investment">
                <CreateInvestmentAccount/>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/userLoan/myLoans')}
            exact
            render={() => (
              <Page title="Hornet Capital - My Loans">
                <>
                  {hasAuthority(role.borrowers, userRoleGroups, activeRole) ? <MyLoansBorrower/> : null}
                  {hasAuthority(role.investors, userRoleGroups, activeRole) ? <MyLoans/> : null}
                  {hasAuthority(role.lenders, userRoleGroups, activeRole) ? <MyLoanLender/> : null}
                  {hasAuthority(role.escrowOfficers, userRoleGroups, activeRole) ?
                    <MyLoansEscrowOfficer/> : null}
                </>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/userLoan/myLoanPayments')}
            exact
            render={() => (
              <Page title="Hornet Capital - Loan Payments">
                <RoleRedirect roles={role.borrowers}>
                  <UserLoanPayments/>
                </RoleRedirect>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/userLoan/budgets')} exact>
            <RoleRedirect roles={role.borrowers}><UserBudget/></RoleRedirect>
          </Route>

          <Route
            path={makeRoutePath(url, '/userLoan/budget/:loanId')} exact>
            <RoleRedirect roles={role.borrowers}><UserBudget/></RoleRedirect>
          </Route>

          <Route
            path={makeRoutePath(url, '/userLoan/payoff-request/:loanId')}
            exact
            render={() => (
              <Page title="Hornet Capital - Payoff Request">
                <>
                  {hasAuthority([...role.borrowers, ...role.escrowOfficers], userRoleGroups, activeRole) &&
					  <UserPayoffRequest/>}
                </>
              </Page>
            )}
          />

          <Route path={makeRoutePath(url, '/userLoan/show/:loanId')}
                 exact
                 render={() => (
                   <Page title="Hornet Capital - Loans">
                     {
                  hasAuthority(role.borrowers, userRoleGroups, activeRole) ?
                    // Don't roadbloack borrowers from viewing their loans
                    <UserLoanView/>
                    :<NdaRoadblock>
                       <UserLoanView/>
                     </NdaRoadblock>}
                   </Page>
                 )}
          />
          <Route
            path={makeRoutePath(url, '/userLoan/createInvestment/:loanId')}
            exact
            render={() => (
              <Page title="Hornet Capital - Create Investment">
                <RoleRedirect roles={role.investors}>
                  <NdaRoadblock><CreateInvestmentView/></NdaRoadblock>
                </RoleRedirect>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/userLoan')}
            exact
            render={() => (
              <Page title="Hornet Capital - Loans Available">
                <RoleRedirect roles={role.investors}>
                  <NdaRoadblock><LoansAvailable/></NdaRoadblock>
                </RoleRedirect>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/userContact/profile')}
            exact
            render={() => (
              <Page title="Hornet Capital - Profile">
                <UserProfileLegacy/>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/bankAccounts')}
            exact
            render={() => (
              <Page title="Hornet Capital - Bank Accounts">
                <BankAccountsPage/>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/transactions')}
            exact
            render={() => (
              <Page title="Hornet Capital - Transactions">
                <TransactionsPage/>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/recurringPayments')}
            exact
            render={() => (
              <Page title="Hornet Capital - Recurring Payments">
                <RoleRedirect roles={role.borrowers}><RecurringLoanPayments/></RoleRedirect>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/recurringInvestments')}
            exact
            render={() => (
              <Page title="Hornet Capital - Recurring Investments">
                <RoleRedirect roles={role.investors}><RecurringInvestments/></RoleRedirect>
              </Page>
            )}
          />

          {/* the fund */}
          <Route
            path={makeRoutePath(url, '/fund')}
            exact
            render={() => (
              <Page title="Hornet Capital - Funds">
                <NdaRoadblock><FundsListView/></NdaRoadblock>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/fund/:fundId(\\d+)')}
            exact
            render={() => (
              <Page title="Hornet Capital - Funds">
                <FundDetailView/>
              </Page>
            )}
          />
          {/* the loan investments */}
          <Route
            path={makeRoutePath(url, '/investments')}
            exact
            render={() => (
              <Page title="Hornet Capital - Investments">
                <LoansListView/>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/investments/list')}
            exact
            render={() => (
              <Page title="Hornet Capital - Loans">
                <LoanParticipationView/>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/loan/create/:id?')}
            exact
            render={() => (
              <Page title="Hornet Capital - Loan Application">
                <RoleRedirect roles={role.borrowers}><BorrowerProjectApplication/></RoleRedirect>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/borrowerApplication/:applicationId(\\d+)')}
            exact
            render={() => (
              <Page title="Hornet Capital - Loan Application">
                <RoleRedirect roles={role.borrowers}><BorrowerProjectApplicationView/></RoleRedirect>
              </Page>
            )}
          />
          {/* Auth routes redirect */}
          <Route path={makeRoutePath(url, '/register')} exact><Redirect to={'/'}/></Route>
          <Route path={makeRoutePath(url, '/login')}><Redirect to={'/'}/></Route>
          <Route path={makeRoutePath(url, '/forgot')} exact><Redirect to={'/'}/></Route>
          <Route path={makeRoutePath(url, '/errorTest')} exact><ErrorTest/></Route>

          <Route
            path={makeRoutePath(url, '/userContact/entities')}
            exact={true}
            render={() => (
              <Page title="Hornet Capital - Entities">
                <EntityView/>
              </Page>
            )}
          />
          <Route
            path={makeRoutePath(url, '/userContact/entities/:entityId(\\d+)')}
            render={() => (
              <Page title="Hornet Capital - Entities">
                <EntityView/>
              </Page>
            )}
          />
          <Route path={makeRoutePath(url, '/e-sign/:envelopeId/:documentId/:signerId')}
                 exact><ESignature/></Route>

          {/*Borrower*/}
          <Route path={makeRoutePath(url, '/forms/borrowerPrequalification')} exact>
            <RoleRedirect roles={role.borrowers}><BorrowerPrequalificationForm/></RoleRedirect>
          </Route>
          <Route path={makeRoutePath(url, '/forms/borrowerProjectApplication')} exact>
            <RoleRedirect roles={role.borrowers}><BorrowerProjectApplication/></RoleRedirect>
          </Route>
          <Route
            path={makeRoutePath(url, '/myTasks')}
            render={() => (
              <Page title="Hornet Capital - Tasks">
                <TasksView/>
              </Page>
            )}
            exact
          />
          <Route
            path={makeRoutePath(url, '/chat/announcement')}
            render={() => (
              <Page title="Hornet Capital - Announcements">
                <AnnouncementView/>
              </Page>
            )}
            exact
          />
          <Route path={makeRoutePath(url, '/bpaSign/:bpaId(\\d+)')}>
            <BpaSign/>
          </Route>

          {/* Ambassador */}
          <Route
            path={makeRoutePath(url, '/ambassador')}
            render={() => (
              <Page title="Hornet Capital - Ambassador">
                <AmbassadorProgram/>
              </Page>
            )}
          />

          <Route
            path={makeRoutePath(url, '/e-sign-request/:eSignIdentifier')}
            render={() => (
              <Page title="Hornet Capital - E-sign">
                <ESignRequest/>
              </Page>
            )}
          />

          {/*Email update*/}
          <Route path={makeRoutePath(url, '/emailUpdateRequest')}><RerouteToLegacy/></Route>

          <Route path={makeRoutePath(url, '/admin')}><Redirect to={'/'}/></Route>
          <Route path={makeRoutePath(url, '/legacy')}><Redirect to={'/'}/></Route>
          <Route path={makeRoutePath(url, '/pending-task/:userUuid')} exact><Redirect to={'/myTasks'}/></Route>
          {
            process.env.NODE_ENV === 'development' ?
              <>
                <Route path={makeRoutePath(url, '/loading')} exact><LoadingTestPage/></Route>
              </>
              :
              null
          }
          {/* not found */}
          <Route path="*"><NotFoundView/></Route>
        </Switch>
      </div>
      <FcmMessagingComponent/>
    </UserLandLayoutViewLegacy>
  );
};

export default RoutesUser;
