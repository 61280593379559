import React from 'react';
import createLazyComponent from '@common/createLazyComponent';

const CreatePageLazyComponent = React.lazy(() => import('./CreatePage'));
const EditPageLazyComponent = React.lazy(() => import('./EditPage'));

export const DeedOfTrustLienCreatePage = createLazyComponent(
    'AdminDeedOfTrustLienCreatePage',
    CreatePageLazyComponent,
    null
);

export const DeedOfTrustLienEditPage = createLazyComponent(
    'AdminDeedOfTrustLienEditPage',
    EditPageLazyComponent,
    null
);

