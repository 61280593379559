import axios from 'axios';
import {getTaskUrl} from './util';
import {Task} from "@interfaces/task";

const getTaskById = async (taskId: string | number, {
  contactId,
  companyId,
}: {
  contactId?: number,
  companyId?: number,
}) => {
  let url = getTaskUrl();
  if (contactId) {
    url += `/contact/${contactId}/task/${taskId}`
  } else if (companyId) {
    url += `/entity/${companyId}/task/${taskId}`
  }
  const response = await axios.get(url);

  return response.data as Task;
};

export default getTaskById;