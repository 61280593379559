import React, {useMemo} from "react";
import {AssociatedLoanForPropertyServer} from "@interfaces/Loan";
import {LoanStageEnum} from "@interfaces/GeneratedEnums";
import {currency, formatDate} from "@common/basic";
import numeral from "numeral";
import {Link} from "react-router-dom";

type Props = {
  row: AssociatedLoanForPropertyServer
  countOfLoans: number
  updatePositionChange: (rowId: number, newPosition: number) => | void;
}
export const AssociatedLoanRow = ({row, countOfLoans, updatePositionChange}: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updatePositionChange(row.id, +e.target.value)
  }
  const options = useMemo(() => {
    return [...Array(countOfLoans + 1).keys()]
      .map((value) => <option key={value} value={`${value}`}>{value}</option>)
  }, [countOfLoans])

  return (<tr key={row.id}>
    <td>
      <select value={`${row.position || ''}`} onChange={handleChange}>
        {options}
      </select>
    </td>
    <td>
      <Link to={`/loan/show/${row.loan.id}`}>
        {row.loan.id}
      </Link>
    </td>
    <td>{row.loan.fundingSourceName}</td>
    <td>{LoanStageEnum[row.loan.loanStage]}</td>
    <td>{formatDate(row.loan.fundingDate)}</td>
    <td>{formatDate(row.loan.maturityDate)}</td>
    <td>{numeral(row.loan.rate).format('0,0.000')}%</td>
    <td>{currency(row.loan.principal)}</td>
    <td>{currency(row.loan.constructionEscrowInitial)}</td>
    <td>{currency(row.loan.constructionEscrowCurrent)}</td>
    <td>{currency(row.loan.prepaidInterestInitial)}</td>
    <td>{currency(row.loan.prepaidInterestCurrent)}</td>
  </tr>);
}