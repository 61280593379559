import React, {useEffect, useMemo, useState} from 'react';
import {Link, useHistory, useParams} from "react-router-dom";
import style from "@admin-ui/pages/PropertyPage/PropertyPage.module.scss";
import AddEditPropertyModal, {openAddEditPropertyModal} from "@admin-ui/pages/PropertyPage/AddEditPropertyModal";
import {MapLoader} from "@components/map";
import {PropertyServer} from "@interfaces/Property";
import DocumentUploadModal from "@admin-ui/components/DocumentUploadModal";
import PropertyDocuments from "@admin-ui/pages/PropertyPage/show/PropertyDocuments";
import AddEditTaxRecordModal from "@admin-ui/pages/PropertyPage/show/AddEditTaxRecordModal";
import {PropertyTaxes} from "@admin-ui/pages/PropertyPage/show/PropertyTaxes";
import {PropertyDetailsShow} from "@admin-ui/pages/PropertyPage/show/PropertyDetailsShow";
import {PropertyInformationShow} from "@admin-ui/pages/PropertyPage/show/PropertyInformationShow";
import {LegalAddress} from "@admin-ui/pages/PropertyPage/show/LegalAddress";
import {AssociatedLoans} from "@admin-ui/pages/PropertyPage/show/AssociatedLoans";
import {getPropertyById} from "@hornet-api/properties/getProperty";
import {alertApiErrors} from "@common/errors";
import {FaPencil} from "react-icons/fa6";
import {FaCamera, FaTrash} from "react-icons/fa";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {deletePropertyById} from "@hornet-api/properties/deleteProperty";
import {addAlert} from "@components/Alert";
import {refreshPropertyList} from "@components/NotificationSidebar/utils";
import {LoanStageEnum} from "@admin/enum/LoanStageEnum";
import {BorrowerApplicationStatusEnum} from "@interfaces/GeneratedEnums";
import {prettyList} from "@common/basic";

type ShowPropertyUrlParams = {
  id: string
}
const ShowPropertyModal = () => {
  const {id} = useParams() as ShowPropertyUrlParams;
  const [property, setProperty] = useState<PropertyServer>();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      const t = loadingTrigger();
      getPropertyById(id)
        .then(setProperty)
        .catch(alertApiErrors)
        .finally(() => loadingRelease(t));
    }
  }, [id])

  const propertyAssociation = useMemo(() => {
    if (!property) return;
    let isPropertyDeletable = true;
    let content = "";
    const keysOfInterest = ["LOANS", "DEED", "BPA", "BPA_SNAPSHOT"];
    for (const key in property.blockingRows) {
      if (keysOfInterest.includes(key) && property.blockingRows[key].length > 0) {
        const rows = property.blockingRows[key];
        switch (key) {
          case "LOANS":
            const loanContent = rows.reduce((acc: string, row) => `${acc} ${row.name} (${row.status ? LoanStageEnum[row.status as keyof typeof LoanStageEnum] : ''}),`, "");
            content += `Loan(s): ${loanContent}`
            isPropertyDeletable = false;
            break;
          case "BPA":
            const bpaContent = rows.reduce((acc: string, row) => `${acc} ${row.name} (${row.status ? BorrowerApplicationStatusEnum[row.status as keyof typeof BorrowerApplicationStatusEnum] : ''}),`, "");
            content += `BPA(s): ${bpaContent}`
            break;
          case "BPA_SNAPSHOT":
            const bpaSnapshotContent = rows.reduce((acc: string, row) => `${acc} ${row.name} (${row.status ? BorrowerApplicationStatusEnum[row.status as keyof typeof BorrowerApplicationStatusEnum] : ''}),`, "");
            content += `BPA snapshot(s): ${bpaSnapshotContent}`
            break;
          case "DEED":
            content += `${rows.length} Deed of trust(s),`;
            isPropertyDeletable = false;
            break;
        }
      }
    }
    const parts = content.split(',').filter(part => part.trim() !== '');
    return {isPropertyDeletable, content: prettyList(parts)};
  }, [property]);

  const onEditClicked = () => {
    openAddEditPropertyModal({
      onComplete: (data: PropertyServer) => {
        addAlert({
          type: 'success',
          content: "Property updated successfully."
        });
        setProperty(data);
      },
      id: Number(id)
    });
  }
  const onDeleteClicked = () => {
    if (!property) return
    if (propertyAssociation && !propertyAssociation.isPropertyDeletable) {
      addAlert({
        type: 'danger',
        content: `Can't delete this property as it is associates with ${propertyAssociation.content}`
      });
      return;
    }
    const confirmMessage = propertyAssociation && propertyAssociation.isPropertyDeletable && propertyAssociation.content
      ? `This property associates with ${propertyAssociation.content}. Are you sure you want to delete this property?`
      : "Are you sure you want to delete this property?";
    if (confirm(confirmMessage)) {
      const t = loadingTrigger();
      deletePropertyById(property.id)
        .then(() => {
          addAlert({
            type: "success",
            content: "Property deleted successfully."
          });
          refreshPropertyList();
          history.push('/property');
        })
        .catch(alertApiErrors)
        .finally(() => loadingRelease(t));
    }
  }

  return (
    <div className={style.container}>
      <section className="content-header">
        <div className="header-title">
          <OverlayTrigger
            placement="top-end"
            overlay={<Tooltip id={`tt-edit-${property?.id}`}>Edit</Tooltip>}
          >
            <Button
              variant={"primary"}
              className="pull-right"
              onClick={onEditClicked}
            >
              <FaPencil/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top-end"
            overlay={<Tooltip id={`tt-img-${property?.id}`}>Image</Tooltip>}
          >
            <Link
              className={`btn btn-warning pull-right mr-2`}
              to={`/uploader/show/${property?.id}`}
            >
              <FaCamera/>
            </Link>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top-end"
            overlay={<Tooltip id={`tt-del-${property?.id}`}>Delete</Tooltip>}
          >
            <Button
              variant={"danger"}
              className="pull-right mr-2"
              onClick={onDeleteClicked}
            >
              <FaTrash/>
            </Button>
          </OverlayTrigger>
          <h1>View Property</h1>
          <small>Viewing Properties</small>
        </div>
      </section>
      <section className="content">
        {
          property && (
            <>
              <PropertyInformationShow property={property}/>
              <PropertyDetailsShow propertyId={property.id} details={property.details}/>
              <PropertyTaxes propertyId={property.id}/>
              <AssociatedLoans propertyId={property.id}/>
              <PropertyDocuments propertyId={property.id}/>
              <LegalAddress
                filename={property.filename || 'No Name'}
                legalAddress={property.legalAddress}
                propertyId={property.id}
                hasLegalAddressDocument={property.hasLegalAddressDocument}
              />
            </>
          )
        }
      </section>
      <AddEditPropertyModal/>
      <DocumentUploadModal/>
      <AddEditTaxRecordModal/>
      <MapLoader/>
    </div>
  );
};

export default ShowPropertyModal;
