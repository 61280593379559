import React, {useEffect, useState} from 'react';
import {mapLoaderState} from "@components/map";
import {Address} from "@admin/api/profileInterfaces";
import {GoogleMap, InfoWindow, Marker} from "@react-google-maps/api";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import getHornetLenderPropertiesList from "@hornet-api/properties/getHornetLenderPropertiesList";
import getHornetPropertiesList from "@hornet-api/properties/getHornetPropertiesList";
import {alertApiErrors} from "@common/errors";
import style from "@legacy/views/LoansAvailable/LoansAvailable.module.scss";
import {LoanStageEnum} from "@interfaces/GeneratedEnums";

interface IHornetPropertyAddress extends Address {
  // Loan Stage will be available only for Hornet Lender Loan's Property Address Type
  loanStage: keyof typeof LoanStageEnum | null;
}

type LegendInputItem = {
  label: string;
  markerSrc: string;
}

const MARKER_ICON_MAP = {
  RED: "/map-marker-red.png",
  YELLOW: "/map-marker-yellow.png",
  ORANGE: "/map-marker-orange.png",
  GREEN: "/map-marker-green.png"
}

enum PROPERTY_TYPE_ENUM {
  PROPERTY_OWNED = "Properties Owned",
  LOAN_PIPELINE = "Loan Pipeline",
  ACTIVE_LOANS = "Active Loans",
  CLOSE_LOANS = "Closed Loans"
}

const PROPERTY_MARKER_MAP = {
  [PROPERTY_TYPE_ENUM.PROPERTY_OWNED]: MARKER_ICON_MAP.RED,
  [PROPERTY_TYPE_ENUM.LOAN_PIPELINE]: MARKER_ICON_MAP.YELLOW,
  [PROPERTY_TYPE_ENUM.ACTIVE_LOANS]: MARKER_ICON_MAP.GREEN,
  [PROPERTY_TYPE_ENUM.CLOSE_LOANS]: MARKER_ICON_MAP.ORANGE
};


const LegendItem = ({label, markerSrc}: LegendInputItem) => (
  <div className={style.legendItem}>
    <div className={style.legendItemIcon}>
      <img alt={label} height={'100%'} src={markerSrc}/>
    </div>
    <span>{label}</span>
  </div>
);

const LegendComponent = () => {
  return (
    <div className={style.legendBox}>
      <div className={style.legendHeading}>Legends</div>
      <div>
        {Object.entries(PROPERTY_MARKER_MAP).map(([key, value]) => (
          <LegendItem key={key} label={key} markerSrc={value}/>
        ))}
      </div>
    </div>
  );
};

const getMarkerIcon = (loanStage: string | null): string => {
  // Loan Stage will be available only for Hornet Lender Loan's Property Address Type
  if (!loanStage) {
    return MARKER_ICON_MAP.RED;
  } else if (loanStage === 'ACTIVE') {
    return MARKER_ICON_MAP.GREEN;
  } else if (loanStage === 'CLOSED') {
    return MARKER_ICON_MAP.ORANGE;
  } else {
    return MARKER_ICON_MAP.YELLOW;
  }
}

const LoansAvailableMap = () => {
  const [map, setMap] = useState<google.maps.Map>();
  const [addresses, setAddresses] = useState<IHornetPropertyAddress[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<number>();
  const isLoaded = mapLoaderState.useValue();

  useEffect(() => {
    if (!map) return
    const t = loadingTrigger();
    Promise.all([
      getHornetLenderPropertiesList(),
      getHornetPropertiesList()
    ])
      .then(([lenderPropertiesAddress, ownerPropertiesAddress]) => {
        // Loan Stage will be null for Hornet Owner Address Type
        return lenderPropertiesAddress.concat(ownerPropertiesAddress.map(address => ({...address, loanStage: null})))
      })
      .then(combinedProperties => {
        return Promise.all(combinedProperties.map(property => {
          return new Promise<IHornetPropertyAddress>((resolve) => {
            if (!property.longitude || !property.latitude) {
              const placesService = new window.google.maps.places.PlacesService(map);
              const query = `${property.address1} ${property.city} ${property.zip}`.replace(/\bnull\b/g, '').trim().replace(/\s+/g, ' ');
              placesService.findPlaceFromQuery({query: query, fields: ['geometry.location']}, (results, status) => {
                if (results && results.length && status !== 'ZERO_RESULTS' && results[0]?.geometry?.location) {
                  property.latitude = results[0].geometry.location.lat().toString();
                  property.longitude = results[0].geometry.location.lng().toString();
                }
                resolve(property);
              });
            } else {
              resolve(property)
            }
          })
        }));
      })
      .then(setAddresses)
      .catch(alertApiErrors)
      .finally(() => {
        loadingRelease(t);
      });
  }, [map]);

  useEffect(() => {
    if (!map) return
    const bounds = new window.google.maps.LatLngBounds();
    const validAddresses = addresses.filter(addr => addr.latitude !== null && addr.longitude !== null);
    validAddresses.forEach(coordinate => {
      bounds.extend(new window.google.maps.LatLng(Number(coordinate.latitude), Number(coordinate.longitude)));
    });
      map.fitBounds(bounds);
  }, [addresses]);

  const showPropertyInfoBox = selectedAddress && selectedAddress > -1 && addresses ? addresses[selectedAddress] : null;

  return (
        <div>
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={{height: '800px', width: '100%'}}
              zoom={3}
              onLoad={(map) => setMap(map)}
              onUnmount={() => setMap(undefined)}
            >
              <LegendComponent/>
              {
                addresses.map((address, index) => {
                  if (address.latitude && address.longitude) {
                    return (
                      <Marker
                        icon={{
                          url: getMarkerIcon(address.loanStage),
                          scaledSize: new window.google.maps.Size(32, 45)
                        }}
                        key={`${address.id}-${index}`}
                        position={{
                          lat: Number(address.latitude),
                          lng: Number(address.longitude)
                        }}
                        onClick={() => setSelectedAddress(index)}
                      />
                    )
                  }
                })
              }
              {
                showPropertyInfoBox && (
                  <InfoWindow
                    position={{
                      lat: Number(showPropertyInfoBox.latitude),
                      lng: Number(showPropertyInfoBox.longitude)
                    }}
                    onCloseClick={() => setSelectedAddress(undefined)}
                  >
                    <div className={style.infoBox}>
                      <div className={style.descriptionBox}>
                        <div>Address: {showPropertyInfoBox.address1}</div>
                        <div>City: {showPropertyInfoBox.city}</div>
                        <div>State: {showPropertyInfoBox.state}</div>
                      </div>
                      <div className={style.clearfix}/>
                    </div>
                  </InfoWindow>
                )
              }
            </GoogleMap>
          )}
        </div>
      )
}

export default LoansAvailableMap;